
import Block from '@/components/ui/block';

export default {
  name: 'Page',

  components: {
    Block,
  },

  props: {
    page: {
      type: Object,
      required: true,
    },
  },

  computed: {
    blocks() {
      return this.page && this.page.acf ? this.page.acf.blocks : [];
    },

    blocksSliced() {
      return this.blocks?.length > 1 ? [...this.blocks].slice(2) : [];
    },

    content() {
      return this.page && this.page.content ? this.page.content.rendered : null;
    },
  },

  mounted() {
    this.checkPageType();
  },

  methods: {
    checkPageType() {
      if (document && this.page?.acf?.page_type === 'campaign') {
        document.querySelector('#__nuxt').classList.add('page_campaign');
      }
    },
  },
};
